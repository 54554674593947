
import { defineComponent, reactive, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
import regExps from "@/utils/regExps";
import { VacationLeaveService } from "@/services/attendance/leave/VacationLeaveService";
import ApprovalStep from "@/components/attendance/approvalStep.vue";
import { CancleLeaveService } from "@/services/attendance/cancelleave/CancelLeaveService";
import { WorkflowService } from "@/services/attendance/workflow/WorkflowService";
export default defineComponent({
  name: "cancelLeaveInfo",
  components: { Header, ApprovalStep },

  setup() {
    const router = useRouter();
    const query = useRoute().query;
    const api = new CancleLeaveService();
    const WorkflowApi = new WorkflowService();
    const leaveApi=new VacationLeaveService();
    let isMore = ref(false);
    const approval = ref(Object.create(null));
    const leaveApproval=ref(Object);
    const stepList = ref(Array);
    let title = ref("销假审批");
    let handleType = ref();
    let userInfo = SystemUtils.loginUser;
    let canRollBack = ref();
    let btnIconRight = reactive(["icon63huanyuan"]);
    let btnSizeRight = reactive(["34px"]);
    const initData = () => {
      if (query) {
        handleType.value = query.handleType;
        getInfo(); 
        getTaskList();
        getDeal();
        if (handleType.value != 0) {
          title.value = "销假详情";
        }
      }
    };
    //获取销期详情
    const getInfo = () => {
      let params = {
        id: query.id,
      };
      api.getInfo(params).then((res: any) => {
        if (res.userId == userInfo.id && res.approveStatus == 0) {
          canStopHandle();
        }
        approval.value = res;
        getleaveInfo()
      });
    };
    //请假详情 
    const getleaveInfo = () => {
      let params = {
        id: approval.value.leaveId,
      };
      leaveApi.getInfo(params).then((res: any) => {
         console.log(res);
        leaveApproval.value = res;
      });
    };
    //获取审批流程
    const getTaskList = () => {
      let params = {
        procId: query.procId,
      };
      WorkflowApi.getTaskList(params).then((res: any) => {
        stepList.value = res;
      });
    };
    //是否显示审批按钮
    const getDeal = () => {
      let params = {
        procId: query.procId,
        taskId: query.taskId,
      };
      WorkflowApi.isDealDoneWorkTask(params).then((res: any) => {
        if (res) {
          handleType.value = 2;
        }
      });
    };
    //是否可撤回
    const canStopHandle = () => {
      let params = {
        procId: query.procId,
      };
      WorkflowApi.canRollBackWorkTask(params).then((res: any) => {
        console.log(res);
        canRollBack.value = res;
      });
    };
    //撤回
    const onFiltClick = () => {
      if (!query.procId) {
        Utils.presentToastWarning("参数异常");
        return;
      }
      let params = {
        procId: query.procId,
      };
      Utils.confirmDialog("撤回", "请确认是撤回销假申请", function () {
        WorkflowApi.forceStopWorkTask(params)
          .then((res: any) => {
            Utils.presentToastWarning("已成功撤回！");
            router.go(-1);
          })
          .catch(() => {
            Utils.presentToastWarning("撤回失败！");
          });
      });
    };
    const subStringUserName = (userName: string) => {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    };
    //审批同意
    const agreeHandle = () => {
      Utils.confirmDialog("同意", "请确认是同意销假申请", function () {
        submitHandle(1);
      });
    };
    let refuseReason = ref();
    //审批不同意
    const refuseHandle = () => {
      Utils.confirmDialog(
        "不同意",
        "<div><textarea style='height:50px;' class='alert-textarea' id='refuseReason' placeholder='请输入不同意原因' rows='8' maxlength='100' :required='true'></textarea></div>",
        function () {
          let textarea = document.getElementById(
            "refuseReason"
          ) as HTMLTextAreaElement;
          let val = textarea.value;
          if (val.replace(/\s+/g, "") == "") {
            Utils.presentToastWarning("请输入不同意原因");
            return;
          }
          if (regExps.SpecChar.test(val)) {
            Utils.presentToastWarning("不同意原因不能输入特殊字符哦！");
            return;
          }
          refuseReason.value = val;
          submitHandle(2);
        }
      );
    };
    const submitHandle = (type: any) => {
      let approveStatus = type == 1 ? true : false;
      let paramSend = {
        procId: query.procId,
        taskId: query.taskId,
        listReciveVar: [
          {
            key: "approve",
            value: approveStatus,
          },
        ],
        option: refuseReason.value,
      };
      WorkflowApi.send(paramSend)
        .then((res: any) => {
          Utils.presentToast("审批成功");
          router.go(-1);
        })
        .catch((err: any) => {
          Utils.presentToastWarning(err);
        });
    };
    const changeisMore = () => {
      isMore.value = true;
    };

    onMounted(() => {
      initData();
    });

    return {
      title,
      btnIconRight,
      btnSizeRight,
      handleType,
      approval,
      stepList,
      isMore,
      subStringUserName,
      changeisMore,
      agreeHandle,
      canRollBack,
      onFiltClick,
      refuseHandle,
      leaveApproval,
    };
  },
});
